interface TrackingEvent {
  event: string
  [data: string]: any
}

interface TrackingFormEvent {
  event: string
  form: string
  formLabel: string
}

export function track(event: TrackingEvent): void {
  const dl: TrackingEvent[] = (window as any).dataLayer ?? []
  dl.push(event)
}

export function trackForm(event: TrackingFormEvent): void {
  track(event)
}
